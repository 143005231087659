import React from 'react';
import { Box, Grid } from '@mui/material';
import { AccountSettingsUser } from '../../../../Accounts/AccountSettings/AccountSettings';
import { LightAssessmentOverviewDetails, AssessmentStatus, AccountInfo } from '../../../type';
import GeneralAssessmentInfoCard from './GeneralAssessmentInfoCard';
import AssessmentResponsesGraphCard from './AssessmentResponsesGraphCard';
import AssessmentDistributionCard from './AssessmentDistributionCard';
import AssessmentReport from './AssessmentReport';
import AssessmentDirectRecipientCard from './AssessmentDirectRecipientCard';

const AssessmentDetailsView = (prop: {
  lightAssessmentDetail: LightAssessmentOverviewDetails; // Update prop
  accountInformation: AccountInfo;
  accountUserData: AccountSettingsUser[];
}) => {
  const { lightAssessmentDetail, accountInformation, accountUserData = [] } = prop;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>

        {lightAssessmentDetail && (
          <Grid container spacing={4} sx={{ display: 'flex', justifyContent: "center" }}>

            <Grid item xs={12} md={lightAssessmentDetail.child_assessment ? 8 : 6}>
              <GeneralAssessmentInfoCard
                accountInfo={accountInformation && accountInformation}
                lightInfo={lightAssessmentDetail} // Update prop
                accountUserData={accountUserData}
                content={lightAssessmentDetail.child_assessment ? 'start' : 'end'}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>

                <Grid item sm={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    marginLeft: '2em',
                  }}
                >
                  < Box
                    sx={{
                      fontSize: '18px',
                      fontWeight: 600,
                      fontFamily: 'Inter'
                      // marginLeft: '2em',
                      // marginBottom: '0em',
                      // paddingBottom: '0em'
                    }}
                  >
                    {lightAssessmentDetail.assessment_definition.assessment_type.name}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      marginLeft: '10px',
                      backgroundColor: '#F7F9FB',
                      padding: '30px 20px',
                      borderRadius: '16px',
                    }}
                  >
                    <AssessmentResponsesGraphCard lightInfo={lightAssessmentDetail} />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  {(lightAssessmentDetail.status !== AssessmentStatus.RESULTS_REVIEW &&
                    lightAssessmentDetail.status !== AssessmentStatus.CLOSED) && (
                      <Box
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#F7F9FB',
                          padding: '30px 20px',
                          borderRadius: '16px',
                        }}
                      >
                        <AssessmentDistributionCard lightInfo={lightAssessmentDetail} />
                        <AssessmentDirectRecipientCard lightInfo={lightAssessmentDetail} />
                      </Box>
                    )}
                </Grid>

                {lightAssessmentDetail &&
                  (lightAssessmentDetail.status == AssessmentStatus.RESULTS_REVIEW ||
                    lightAssessmentDetail.status == AssessmentStatus.CLOSED) && (
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#F7F9FB',
                          padding: '30px 20px',
                          borderRadius: '16px',
                        }}
                      >
                        <AssessmentReport lightInfo={lightAssessmentDetail} />
                      </Box>
                    </Grid>
                  )}

              </Grid>
            </Grid>

            {lightAssessmentDetail.child_assessment && (
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      marginLeft: '2em',
                    }}
                  >
                    < Box
                      sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        fontFamily: 'Inter'
                        // marginLeft: '2em',
                        // marginBottom: '0em',
                        // paddingBottom: '0em'
                      }}
                    >
                      {lightAssessmentDetail.child_assessment.assessment_definition.assessment_type.name}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box
                      sx={{
                        marginLeft: '10px',
                        backgroundColor: '#F7F9FB',
                        padding: '30px 20px',
                        borderRadius: '16px',
                      }}
                    >
                      <AssessmentResponsesGraphCard lightInfo={lightAssessmentDetail.child_assessment} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {(lightAssessmentDetail.child_assessment.status !== AssessmentStatus.RESULTS_REVIEW &&
                      lightAssessmentDetail.child_assessment.status !== AssessmentStatus.CLOSED) && (
                        <Box
                          sx={{
                            marginLeft: '10px',
                            backgroundColor: '#F7F9FB',
                            padding: '30px 20px',
                            borderRadius: '16px',
                          }}
                        >
                          <AssessmentDistributionCard lightInfo={lightAssessmentDetail.child_assessment} />
                          <AssessmentDirectRecipientCard lightInfo={lightAssessmentDetail.child_assessment} />
                        </Box>
                      )}
                  </Grid>

                  {lightAssessmentDetail.child_assessment &&
                    (lightAssessmentDetail.child_assessment.status == AssessmentStatus.RESULTS_REVIEW ||
                      lightAssessmentDetail.child_assessment.status == AssessmentStatus.CLOSED) && (
                      <Grid item xs={12} sm={12}>
                        <Box
                          sx={{
                            marginLeft: '10px',
                            backgroundColor: '#F7F9FB',
                            padding: '30px 20px',
                            borderRadius: '16px',
                          }}
                        >
                          <AssessmentReport lightInfo={lightAssessmentDetail.child_assessment} />
                        </Box>
                      </Grid>
                    )}

                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid >
  );
};

export default AssessmentDetailsView;
